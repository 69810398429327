<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-my-stats-submenu v-if="showSubmenu !== false"></q-my-stats-submenu>
        <v-row wrap class="mx-7">
            <v-col class="pa-0" cols="12" v-if="showSubmenu !== false">
                <h3 v-if="agent">
                    {{agent.AgentName}} - HQ Usage Stats
                </h3>
            </v-col>
            <v-row :class="!$vuetify.breakpoint.sm && 'flex-wrap-reverse'">
                <v-col cols="12" sm="8" md="9" lg="10" v-if="agent">
                    <v-row wrap>
                        <v-col class="pmx-3" cols="12" md="6" lg="3">
                            <q-score-card-hq-active :agent-code="agent_code" :filters="filters" key="hq-active">
                            </q-score-card-hq-active>
                        </v-col>
                        <v-col class="pmx-3" cols="12" md="6" lg="3" v-if="hasRole(['SuperAdmin', 'Exec', 'Staff'])">
                            <q-score-card-hq-active-staff :agent-code="agent_code" :filters="filters" key="hq-active-staff">
                            </q-score-card-hq-active-staff>
                        </v-col>
                        <v-col class="px-3" cols="12" md="6" lg="3">
                            <q-score-card-hq-logins :agent-code="agent_code" :filters="filters" key="hq-logins">
                            </q-score-card-hq-logins>
                        </v-col>
                        <v-col class="px-3" cols="12" md="6" lg="3">
                            <q-score-card-hq-logins-staff :agent-code="agent_code" :filters="filters" key="hq-logins-staff">
                            </q-score-card-hq-logins-staff>
                        </v-col>
                        <v-col class="px-3" cols="12" md="6" lg="3">
                            <q-score-card-promotions :agent-code="agent_code" :filters="filters" key="promotions">
                            </q-score-card-promotions>
                        </v-col>
                        <v-col class="px-3" cols="12" md="6" lg="3">
                            <q-score-card-birthdays :agent-code="agent_code" :filters="filters" key="birthdays"></q-score-card-birthdays>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="4" md="3" lg="2" v-if="agent">
                    <q-score-card-filters v-model="filters" :agent="agent" v-on:agent="changeAgent" :allow-agent-lookup="allowAgentLookup"></q-score-card-filters>
                </v-col>
            </v-row>
        </v-row>
    </v-container>
</template>
<script>
import QMyStatsSubmenu from '@/components/navigation/Submenus/QMyStatsSubmenu.vue';
import QScoreCardHqActive from '@/components/stats/scorecards/QScoreCardHqActive.vue';
import QScoreCardHqActiveStaff from '@/components/stats/scorecards/QScoreCardHqActiveStaff.vue';
import QScoreCardHqLogins from '@/components/stats/scorecards/QScoreCardHqLogins.vue';
import QScoreCardHqLoginsStaff from '@/components/stats/scorecards/QScoreCardHqLoginsStaff.vue';
import QScoreCardPromotions from '@/components/stats/scorecards/QScoreCardHqPromotions.vue';
import QScoreCardBirthdays from '@/components/stats/scorecards/QScoreCardHqBirthdays.vue';
import QScoreCardFilters from '@/components/stats/scorecards/QScoreCardFilters.vue';
import { debounce } from 'vue-debounce'
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { isDisputeTimePeriod } from '@/components/utils/BusinessTimeDateDiff'
import QScoreCardSummitStarted from "@/components/stats/scorecards/QScoreCardSummitStarted";
import QScoreCardSummitStartedPeriodic from "@/components/stats/scorecards/QScoreCardSummitStartedPeriodic";
import QSummitProgressTable from "@/components/datatables/QSummitProgressTable";

export default {
    props: {
        showSubmenu: {
            type: Boolean,
            default: true
        },
        allowAgentLookup: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            agentContractLevel: null,
            filters: {
                startDate: null,
                endDate: null,
                timePeriod: 'month',
                statType: null
            },
            agent: null
        }
    },
    mounted: function() {
        this.getAgentContractLevel(this.agentCode)
    },
    computed: {
        agent_code: function() {
            if (typeof this.$route.params.agentcode != 'undefined' && this.$route.params.agentcode != null) {
                return this.$route.params.agentcode;
            }
            return this.user.Agent.AgentCode;
        },
        canDispute: function() {
            return isDisputeTimePeriod()
        },
    },

    methods: {
        getAgentContractLevel(agentCode) {
            var g = this
            QuilityAPI.getAgentStateless(this.agent_code).then(function(json) {
                if (typeof json.ContractLevel != 'undefined') {
                    g.agent = json;
                    g.agentContractLevel = json.ContractLevel;
                    return
                }
            })
        },
        changeAgent(agentCode) {
            if (typeof agentCode == 'undefined') {
                this.$router.push('/my_stats/scorecard/hq/');
                return
            }
            this.$router.push('/my_stats/scorecard/hq/' + agentCode);
        },
        viewAgent: function() {
            this.$router.push('/agents/' + this.agent_code);
        },
        launchDisputeForm() {
            // open link to dispute form
            // housed in jot forms
            window.open('https://form.jotform.com/211966371426055', '_blank');
        },
    },

    watch: {
        'agent_code': function(newV) {
            var g = this
            g.getAgentContractLevel(newV)
        }
    },
    components: {
        QScoreCardHqActive,
        QScoreCardHqActiveStaff,
        QScoreCardHqLogins,
        QScoreCardPromotions,
        QScoreCardBirthdays,
        QMyStatsSubmenu,
        QScoreCardFilters,
        QScoreCardHqLoginsStaff
    }
}
</script>
